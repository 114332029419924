// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Board from "../Board.bs.js";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var InvalidDifference = /* @__PURE__ */Caml_exceptions.create("Cell.InvalidDifference");

var InvalidHeadPosition = /* @__PURE__ */Caml_exceptions.create("Cell.InvalidHeadPosition");

var BadArgument = /* @__PURE__ */Caml_exceptions.create("Cell.BadArgument");

var InvalidLengthOrId = /* @__PURE__ */Caml_exceptions.create("Cell.InvalidLengthOrId");

var TailNextNotFound = /* @__PURE__ */Caml_exceptions.create("Cell.TailNextNotFound");

var ExcessiveTailRecursion = /* @__PURE__ */Caml_exceptions.create("Cell.ExcessiveTailRecursion");

function findSnakeHead(cells) {
  return Belt_Option.getExn(Caml_option.undefined_to_opt(cells.find(function (c) {
                      return c.isHead === true;
                    })));
}

var max = {
  contents: 0
};

function findTail(_cell, cells) {
  while(true) {
    var cell = _cell;
    max.contents = max.contents + 1 | 0;
    var c = cell.prev;
    if (c !== undefined) {
      if (max.contents > 1000) {
        throw {
              RE_EXN_ID: ExcessiveTailRecursion,
              Error: new Error()
            };
      }
      _cell = Belt_Option.getExn(Caml_option.undefined_to_opt(cells.find((function(c){
                  return function (cell) {
                    return cell.location === c;
                  }
                  }(c)))));
      continue ;
    }
    max.contents = 0;
    return cell.location;
  };
}

function checkBorderCollision(newTailLocation, nextPos) {
  if (newTailLocation > Board.area || newTailLocation < 0 || Caml_int32.mod_(newTailLocation, Board.width) === (Board.width - 1 | 0) && nextPos === /* Left */0 || Caml_int32.mod_(newTailLocation, Board.width) === 0 && nextPos === /* Right */1) {
    return {
            TAG: /* Error */1,
            _0: /* GameEnding */0
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

function checkInvalidTurn(newTailLocation, head) {
  var headPrev = Belt_Option.getExn(head.prev);
  if (headPrev === newTailLocation) {
    return {
            TAG: /* Error */1,
            _0: /* PreventAction */1
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

function checkSelfCollision(newTailLocation, _head, cells) {
  while(true) {
    var head = _head;
    var id = head.prev;
    if (id === undefined) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    }
    if (id === newTailLocation) {
      return {
              TAG: /* Error */1,
              _0: /* GameEnding */0
            };
    }
    var childCell = Belt_Option.getExn(Caml_option.undefined_to_opt(cells.find((function(id){
                return function (cell) {
                  return cell.location === id;
                }
                }(id)))));
    _head = childCell;
    continue ;
  };
}

function moveTailToHead(cells, head, newTailLocation) {
  var tailId = findTail(head, cells);
  var tail = Belt_Option.getExn(Caml_option.undefined_to_opt(cells.find(function (c) {
                return c.location === tailId;
              })));
  var id = tail.next;
  var tailNext;
  if (id !== undefined) {
    tailNext = Belt_Option.getExn(Caml_option.undefined_to_opt(cells.find(function (c) {
                  return c.location === id;
                })));
  } else {
    console.log(cells);
    throw {
          RE_EXN_ID: TailNextNotFound,
          _1: String(tail.location),
          Error: new Error()
        };
  }
  var oldHead_next = newTailLocation;
  var oldHead_prev = head.prev;
  var oldHead_isApple = head.isApple;
  var oldHead_location = head.location;
  var oldHead = {
    next: oldHead_next,
    prev: oldHead_prev,
    isHead: false,
    isApple: oldHead_isApple,
    location: oldHead_location
  };
  var newHead_prev = head.location;
  var newHead = {
    next: undefined,
    prev: newHead_prev,
    isHead: true,
    isApple: false,
    location: newTailLocation
  };
  var newTail_next = tailNext.next;
  var newTail_isHead = tailNext.isHead;
  var newTail_isApple = tailNext.isApple;
  var newTail_location = tailNext.location;
  var newTail = {
    next: newTail_next,
    prev: undefined,
    isHead: newTail_isHead,
    isApple: newTail_isApple,
    location: newTail_location
  };
  return Belt_Array.mapWithIndex(cells, (function (i, cell) {
                if (oldHead_location === i) {
                  return oldHead;
                } else if (newTailLocation === i) {
                  return newHead;
                } else if (newTail_location === i) {
                  return newTail;
                } else if (tail.location === i) {
                  return {
                          next: undefined,
                          prev: undefined,
                          isHead: false,
                          isApple: tail.isApple,
                          location: tail.location
                        };
                } else {
                  return cell;
                }
              }));
}

function validateLength(id, length) {
  if (!Caml_obj.greaterthan(length, id)) {
    return ;
  }
  throw {
        RE_EXN_ID: InvalidLengthOrId,
        Error: new Error()
      };
}

function appendToSnake(_cells, _id, _length, nextDir) {
  while(true) {
    var length = _length;
    var id = _id;
    var cells = _cells;
    validateLength(id, length);
    var nextId;
    switch (nextDir) {
      case /* Left */0 :
          nextId = id - 1 | 0;
          break;
      case /* Right */1 :
          nextId = id + 1 | 0;
          break;
      case /* Up */2 :
          nextId = id - Board.width | 0;
          break;
      case /* Down */3 :
          nextId = id + Board.width | 0;
          break;
      
    }
    if (length === 0) {
      return cells;
    }
    var cell = Belt_Option.getExn(Belt_Array.get(cells, id));
    var c = Belt_Array.get(cells, nextId);
    var next = c !== undefined ? c.location : undefined;
    var c$1 = Belt_Array.get(cells, id - 1 | 0);
    var prev = c$1 !== undefined && length > 1 ? c$1.location : undefined;
    _length = length - 1 | 0;
    _id = id - 1 | 0;
    _cells = Belt_Array.map(cells, (function(cell,next,prev){
        return function (c) {
          if (c.location === cell.location) {
            return {
                    next: next,
                    prev: prev,
                    isHead: c.isHead,
                    isApple: c.isApple,
                    location: c.location
                  };
          } else {
            return c;
          }
        }
        }(cell,next,prev)));
    continue ;
  };
}

function inferDirectionFromNext(cell) {
  var id = cell.next;
  var nextId;
  if (id !== undefined) {
    nextId = id;
  } else {
    throw {
          RE_EXN_ID: BadArgument,
          _1: "Cannot infer direction from head. Use nextDir state instead",
          Error: new Error()
        };
  }
  var difference = nextId - cell.location | 0;
  if (difference === 1) {
    return /* Right */1;
  }
  if (difference === -1) {
    return /* Left */0;
  }
  if (difference === Board.width) {
    return /* Down */3;
  }
  if (difference === Math.imul(Board.width, -1)) {
    return /* Up */2;
  }
  throw {
        RE_EXN_ID: InvalidDifference,
        _1: difference,
        Error: new Error()
      };
}

function generateNewAppleLocation(currentLocation, _newLocation) {
  while(true) {
    var newLocation = _newLocation;
    if (currentLocation !== newLocation) {
      return newLocation;
    }
    _newLocation = Js_math.random_int(0, Board.area - 1 | 0);
    continue ;
  };
}

function handleApple(cells, appleLocation, head) {
  if (head.location !== appleLocation) {
    return [
            cells,
            appleLocation
          ];
  }
  var tailId = findTail(head, cells);
  var tail = Belt_Option.getExn(Caml_option.undefined_to_opt(cells.find(function (c) {
                return c.location === tailId;
              })));
  var inferredDirection = inferDirectionFromNext(tail);
  var newAppleLocation = generateNewAppleLocation(appleLocation, Js_math.random_int(0, Board.area - 1 | 0));
  var newPrevOffset;
  switch (inferredDirection) {
    case /* Left */0 :
        newPrevOffset = 1;
        break;
    case /* Right */1 :
        newPrevOffset = -1;
        break;
    case /* Up */2 :
        newPrevOffset = Board.width;
        break;
    case /* Down */3 :
        newPrevOffset = Math.imul(Board.width, -1);
        break;
    
  }
  var prevTail_next = tail.next;
  var prevTail_prev = tail.location + newPrevOffset | 0;
  var prevTail_isHead = tail.isHead;
  var prevTail_isApple = tail.isApple;
  var prevTail_location = tail.location;
  var prevTail = {
    next: prevTail_next,
    prev: prevTail_prev,
    isHead: prevTail_isHead,
    isApple: prevTail_isApple,
    location: prevTail_location
  };
  var c = Belt_Array.mapWithIndex(appendToSnake(cells, tail.location + newPrevOffset | 0, 1, inferredDirection), (function (i, cell) {
          if (i === prevTail_location) {
            return prevTail;
          } else if (i === newAppleLocation) {
            return {
                    next: cell.next,
                    prev: cell.prev,
                    isHead: cell.isHead,
                    isApple: true,
                    location: cell.location
                  };
          } else {
            return cell;
          }
        }));
  return [
          c,
          newAppleLocation
        ];
}

function getNewTailLocation(nextPos, head) {
  switch (nextPos) {
    case /* Left */0 :
        return head.location - 1 | 0;
    case /* Right */1 :
        return head.location + 1 | 0;
    case /* Up */2 :
        return head.location - Board.width | 0;
    case /* Down */3 :
        return head.location + Board.width | 0;
    
  }
}

function validateDirectionChange(nextPos, cells) {
  var head = findSnakeHead(cells);
  return checkInvalidTurn(getNewTailLocation(nextPos, head), head);
}

function handleTick(cells, nextPos, appleLocation) {
  var head = findSnakeHead(cells);
  var match = handleApple(cells, appleLocation, head);
  var appleLocation$1 = match[1];
  var c = match[0];
  var newTailLocation = getNewTailLocation(nextPos, head);
  return Belt_Result.flatMap(Belt_Result.flatMap(Belt_Result.flatMap(checkInvalidTurn(newTailLocation, head), (function (param) {
                        return checkSelfCollision(newTailLocation, head, cells);
                      })), (function (param) {
                    return checkBorderCollision(newTailLocation, nextPos);
                  })), (function (param) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          newCells: moveTailToHead(c, head, newTailLocation),
                          appleLocation: appleLocation$1
                        }
                      };
              }));
}

function initSnake(cells) {
  var c = Belt_Array.get(cells, Board.initSnakeHeadLocation);
  var cell;
  if (c !== undefined) {
    cell = c;
  } else {
    throw {
          RE_EXN_ID: InvalidHeadPosition,
          Error: new Error()
        };
  }
  var c$1 = Belt_Array.get(cells, cell.location - 1 | 0);
  var headCell_next = cell.next;
  var headCell_prev = c$1 !== undefined ? c$1.location : undefined;
  var headCell_isApple = cell.isApple;
  var headCell_location = cell.location;
  var headCell = {
    next: headCell_next,
    prev: headCell_prev,
    isHead: true,
    isApple: headCell_isApple,
    location: headCell_location
  };
  return appendToSnake(Belt_Array.map(cells, (function (c) {
                    if (headCell_location === c.location) {
                      return headCell;
                    } else {
                      return c;
                    }
                  })), headCell_location - 1 | 0, Board.initSnakeSize - 1 | 0, /* Right */1);
}

function initCells(appleLocation) {
  var cells = [];
  for(var i = 0; i < Board.area; ++i){
    cells.push({
          next: undefined,
          prev: undefined,
          isHead: false,
          isApple: appleLocation === i,
          location: i
        });
  }
  return initSnake(cells);
}

function isCellSnake(cell) {
  if (Belt_Option.isSome(cell.next) || Belt_Option.isSome(cell.prev)) {
    return true;
  } else {
    return cell.isHead === true;
  }
}

var make = React.memo(function (Props) {
      var cell = Props.cell;
      var isSnake = isCellSnake(cell);
      var match = cell.isApple;
      return React.createElement("div", {
                  style: {
                    backgroundColor: isSnake ? "green" : (
                        match ? "red" : "gray"
                      ),
                    height: Board.cellSizePx,
                    width: Board.cellSizePx
                  }
                });
    });

export {
  InvalidDifference ,
  InvalidHeadPosition ,
  BadArgument ,
  InvalidLengthOrId ,
  TailNextNotFound ,
  ExcessiveTailRecursion ,
  findSnakeHead ,
  max ,
  findTail ,
  checkBorderCollision ,
  checkInvalidTurn ,
  checkSelfCollision ,
  moveTailToHead ,
  validateLength ,
  appendToSnake ,
  inferDirectionFromNext ,
  generateNewAppleLocation ,
  handleApple ,
  getNewTailLocation ,
  validateDirectionChange ,
  handleTick ,
  initSnake ,
  initCells ,
  isCellSnake ,
  make ,
}
/* make Not a pure module */
