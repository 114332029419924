// Generated by ReScript, PLEASE EDIT WITH CARE


var area = 400;

var cellSizePx = String(20) + "px";

var rowWidth = 400;

var rowWidthPx = String(rowWidth) + "px";

var initSnakeHeadLocation = 210;

var tickInterval = 200;

var width = 20;

var height = 20;

var cellSize = 20;

var initSnakeSize = 4;

export {
  tickInterval ,
  width ,
  height ,
  area ,
  cellSize ,
  cellSizePx ,
  rowWidth ,
  rowWidthPx ,
  initSnakeSize ,
  initSnakeHeadLocation ,
}
/* cellSizePx Not a pure module */
